import React, { MouseEventHandler } from 'react'
import ComponentInjector from '@frontastic/catwalk/src/js/app/injector'
import Link from 'next/link'
import Reference from '@frontastic/catwalk/src/js/component/reference'
import classnames from 'classnames'
import { isAnchorTag } from 'anwr_sport2000/src/js/utils/helpers/urlHelper';
import { getReferenceTarget } from 'helpers/reference';
import AnchorLink from 'anwr_sport2000/src/js/patterns/atoms/anchor';

export type Props = {
  type: 'button' | 'submit' | 'reset' | undefined
  ariaLabel: string
  className?: string
  id?: string
  disabled?: boolean
  children?: React.ReactNode
  onClick?: MouseEventHandler
  darkTheme?: boolean
  fullWidth?: boolean
  reference?: Reference
  link?: any
  style?: React.CSSProperties,
  forwardedRef?: React.RefObject<HTMLInputElement>,
  onMouseEnter: MouseEventHandler
}

const Button: React.FC<Props> = ({
  type = 'button',
  ariaLabel,
  className,
  disabled = false,
  children,
  onClick = () => {
  },
  darkTheme = false,
  fullWidth = false,
  reference,
  link,
  style,
  forwardedRef,
  onMouseEnter = () => {
  },
  id,
}: Props) => {
  const additionalStyle = {
    'dark-mode': darkTheme,
    'full-width': fullWidth,
  }
  const renderButton = () => (
    <button
      // @ts-ignore
      className={classnames(className, additionalStyle)}
      // eslint-disable-next-line react/button-has-type
      type={type}
      aria-label={ariaLabel}
      disabled={disabled}
      onClick={onClick}
      style={style}
      // @ts-ignore
      ref={forwardedRef}
      onMouseEnter={onMouseEnter}
      id={id}
    >
      {children && children}
    </button>
  )

  if (reference && isAnchorTag(getReferenceTarget(reference))) {
    return (
      <AnchorLink
        href={getReferenceTarget(reference)}
        // @ts-ignore
        className={classnames(className, additionalStyle)}
      >
        {children && children}
      </AnchorLink>
    )
  }

  if (reference) {
    return (
      <Reference
        reference={reference}
        // @ts-ignore
        className={classnames(className, additionalStyle)}
      >
        {children && children}
      </Reference>
    )
  }

  if (link) {
    return (
      <Link
        href={link}
        // @ts-ignore
        className={classnames(className, additionalStyle)}
      >
        {children && children}
      </Link>
    )
  }

  return renderButton()
}

export default ComponentInjector.return('Button', Button)
