import React, { ReactNode, useCallback, useMemo } from 'react';
import { animateScroll as scroll, scroller } from 'react-scroll';
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import Link from 'next/link';

interface AnchorLinkProps {
  href: string;
  children: ReactNode;
  className?: string;
}

const AnchorLink: React.FC<AnchorLinkProps> = ({ href, children, className }) => {
  const isDesktop = useDeviceType() === 'desktop'

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      const elementId = href.substring(1);

      // Scroll to the element
      scroller.scrollTo(elementId, {
        duration: 300,
        smooth: true,
        offset: isDesktop ? -150 : 0, // Offset for the sticky header on desktop
      });

      // Update the URL hash
      window.history.pushState(null, '', href);
    },
    [href, isDesktop]
  );

  return (
    <Link href={href} onClick={(e) => handleClick(e)} className={className}>
      {children}
    </Link>
  );
};

export default AnchorLink;